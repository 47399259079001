import { Action, Subject } from "@/libs/acl/config";

export default [
  {
    header: 'Administration',
    resource: Subject.DASHBOARD,
    action: Action.Read
  },
  {
    title: 'Trips',
    icon: 'DiscIcon',
    children: [
      {
        title: 'View List',
        route: 'view-trips',
        resource: Subject.TRIP,
        action: Action.Read
      },
      // {
      //   title: 'View Trips',
      //   route: 'view-trips',
      // },
      // {
      //   title: 'Disable Account',
      //   route: 'disable-account',
      // },
      // {
      //   title: 'Track Driver',
      //   route: 'track-driver',
      // },
      // {
      //   title: 'Manage Documents',
      //   route: 'manage-documents',
      // },
    ],
  },
  {
    title: 'Drivers',
    icon: 'UserIcon',
    children: [
      {
        title: 'View Driver List',
        route: 'apps-drivers-list',
        resource: Subject.RIDER,
        action: Action.Read
      },
      // {
      //   title: 'View Trips',
      //   route: 'view-trips',
      // },
      // {
      //   title: 'Disable Account',
      //   route: 'disable-account',
      // },
      // {
      //   title: 'Track Driver',
      //   route: 'track-driver',
      // },
      // {
      //   title: 'Manage Documents',
      //   route: 'manage-documents',
      // },
    ],
  },

  {
    title: 'Users',
    icon: 'UserIcon',
    children: [
      {
        title: 'View List',
        route: 'view-users-list',
        resource: Subject.USER,
        action: Action.Read
      },
      // {
      //   title: 'Disable User',
      //   route: 'disable-user',
      // },
      // {
      //   title: 'View Trips',
      //   route: 'view-trips',
      // },
      // {
      //   title: 'Track Driver',
      //   route: 'track-driver',
      // },
      // {
      //   title: 'Manage Documents',
      //   route: 'manage-documents',
      // },
    ],
  },

  {
    title: 'Ride Types',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'List',
        route: 'ride-type-list',
        resource: Subject.RIDE_TYPE,
        action: Action.Read
      },
    ],
  },

  {
    title: 'Rates',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'List',
        route: 'rate-list',
        resource: Subject.RATE,
        action: Action.Read
      },
      // {
      //   title: 'Ride Price/Min',
      //   route: 'ride-price-per-min',
      // },
    ],
  },

  // {
  //   title: 'Report',
  //   icon: 'EyeOffIcon',
  //   children: [
  //     {
  //       title: 'Revenue Reports',
  //       route: 'revenue-reports',
  //     },
  //     {
  //       title: 'Transaction Reports',
  //       route: 'transaction-reports',
  //     },
  //     {
  //       title: 'Route Reports',
  //       route: 'route-reports',
  //     },
  //     {
  //       title: 'Earning Report',
  //       route: 'earning-reports',
  //     },
  //   ],
  // },

  // {
  //   title: 'Locations',
  //   icon: 'HomeIcon',
  //   children: [
  //     {
  //       title: 'Location List',
  //       route: 'location-list',
  //     },
  //     {
  //       title: 'Pickups',
  //       route: 'pickup-list',
  //     },
  //   ],
  // },

  {
    title: 'Reviews',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Driver Reviews',
        route: 'driver-reviews',
        resource: Subject.RIDER_REVIEW,
        action: Action.Read
      },
      {
        title: 'User Reviews',
        route: 'user-reviews',
        resource: Subject.USER_REVIEW,
        action: Action.Read
      },
    ],
  },
  {
    title: 'Marketing',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Coupons',
        route: 'coupon',
        resource: Subject.COUPON,
        action: Action.Read
      },
      {
        title: 'Referrals',
        route: 'referral',
        resource: Subject.REFERRAL,
        action: Action.Read
      },
    ],
  },
  {
    title: 'Earnings',
    icon: 'GiftIcon',
    route: 'earning',
    resource: Subject.PAYMENT,
    action: Action.Read
  },
  {
    header: 'Access Control',
    resource: Subject.DASHBOARD,
    action: Action.Read
  },
  {
    title: 'Account',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Admins',
        route: 'admin',
        resource: Subject.ADMIN,
        action: Action.Read
      },
      {
        title: 'Roles',
        route: 'role',
        resource: Subject.ROLE,
        action: Action.Read
      },
      {
        title: 'Permissions',
        route: 'permission',
        resource: Subject.PERMISSION,
        action: Action.Read
      },
      // {
      //   title: 'Policies',
      //   route: 'policy',
      //   resource: Subject.ROLE,
      //   action: Action.Read
      // },
    ],
  },
]
