import { Action, Subject } from "@/libs/acl/config";


export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard',
    resource: Subject.DASHBOARD,
    action: Action.Read
    // tag: '2',
    // tagVariant: 'light-warning',
    // children: [
    //   {
    //     title: 'eCommerce',
    //     route: 'dashboard-ecommerce',
    //   },
    //   {
    //     title: 'Analytics',
    //     route: 'dashboard-analytics',
    //   },
    // ],
  },
  {
    title: 'Ongoing',
    icon: 'DiscIcon',
    route: 'ongoing-trips',
    tag: "0",
    tagVariant: 'light-warning',
    resource: Subject.TRIP,
    action: Action.Read
  },
  {
    title: 'Scheduled',
    icon: 'DiscIcon',
    route: 'scheduled',
    tag: '0',
    tagVariant: 'light-warning',
    resource: Subject.TRIP,
    action: Action.Read
  },
  {
    title: 'Riders Online',
    icon: 'CrosshairIcon',
    route: 'track-driver',
    tag: '0',
    tagVariant: 'light-warning',
    resource: Subject.RIDER,
    action: Action.Read
  }
]
